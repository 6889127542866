import React from "react";

import { useTranslation, Trans } from "react-i18next";

import main from '../../img/Products/main.png'
import check from '../../img/check.png'

import Fade from 'react-reveal/Fade'
import Button from "../Button";

function Main () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto mt-[50px]">
                <div>
                    <div className="flex flex-col">
                        <p className="text-[#FF2C57] font-[700] text-[35px] xl:text-[70px] pepsi tracking-wider">{t('products.1')}</p>
                        <p className="inter text-[#303030] font-[700] text-[25px] xl:text-[35px]"><Trans>{t('products.2')}</Trans></p>
                        <div className="flex items-center gap-[20px] mt-[20px]">
                            <img src={check} alt="" />
                            <p className="inter text-[16px] xl:text-[25px] font-[700] text-[#303030]">{t('products.3')}</p>
                        </div>
                        <div className="flex items-center gap-[20px] mt-[20px]">
                            <img src={check} alt="" />
                            <p className="inter text-[16px] xl:text-[25px] font-[700] text-[#303030]">{t('products.4')}</p>
                        </div>
                        <div className="flex items-center gap-[20px] mt-[20px]">
                            <img src={check} alt="" />
                            <p className="inter text-[16px] xl:text-[25px] font-[700] text-[#303030]">{t('products.5')}</p>
                        </div>
                        <div className="flex items-center gap-[20px] mt-[20px]">
                            <img src={check} alt="" />
                            <p className="inter text-[16px] xl:text-[25px] font-[700] text-[#303030]">{t('products.6')}</p>
                        </div>
                        <Button className="mt-[50px]"/>
                    </div>    
                </div>
                <div>
                    <Fade right>
                        <img src={main} alt="" />
                    </Fade>   
                </div>
            </div>
        </div>
    );
}

export default Main;