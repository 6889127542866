import React, { useState } from 'react';

import { useTranslation, Trans } from "react-i18next";

import Button from "../Button";

function Switcher1 () {

    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState('forex');

    const renderContent = () => {
        switch(activeTab) {
          case 'forex':
            return <div>
                        <p className="text-[#303030] pepsi text-[25px]">{t('services.4')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('services.7')}</p>
                   </div>;
          case 'global-markets':
            return <div>
                        <p className="text-[#303030] pepsi text-[25px]">{t('services.5')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('services.8')}</p>
                   </div>;
          case 'stocks':
            return <div>
                        <p className="text-[#303030] pepsi text-[25px]">{t('services.6')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('services.9')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('services.10')}</p>
                   </div>;
          default:
            return null;
        }
      };
      
    return(
        <div className="overflow-hidden relative">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px]">
                <div className="xl:w-[279px] flex flex-col gap-[40px]">
                    <div className='flex flex-col gap-[15px]'>
                        <button className={`font-[500] transition-all inter h-[43px] rounded-[25px] ${activeTab === 'forex' ? 'bg-[#2050A0] text-white' : 'bg-[#E4E4E4] text-[#303030]'}`} onClick={() => setActiveTab('forex')}>{t('services.4')}</button>
                        <button className={`font-[500] transition-all inter h-[43px] rounded-[25px] ${activeTab === 'global-markets' ? 'bg-[#2050A0] text-white' : 'bg-[#E4E4E4] text-[#303030]'}`} onClick={() => setActiveTab('global-markets')}>{t('services.5')}</button>
                        <button className={`font-[500] transition-all inter h-[43px] rounded-[25px] ${activeTab === 'stocks' ? 'bg-[#2050A0] text-white' : 'bg-[#E4E4E4] text-[#303030]'}`} onClick={() => setActiveTab('stocks')}>{t('services.6')}</button>
                    </div>
                </div>
                <div className='max-w-[900px] mt-[30px] xl:mt-0'>
                    {renderContent()}
                </div>
            </div>
        </div>
    );
}

export default Switcher1;