import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Button from "../Button";

import button from '../../img/button.svg'

const faqData = [
    {
        question: 'home.11',
        answer: 'home.12'
    },
    {
        question: 'home.13',
        answer: 'home.14'
    },
    {
        question: 'home.15',
        answer: 'home.16'
    },
    {
        question: 'home.17',
        answer: 'home.18'
    },
    {
        question: 'home.19',
        answer: 'home.20'
    },
    {
        question: 'home.21',
        answer: 'home.22'
    },
    {
        question: 'home.23',
        answer: 'home.24'
    },
]

function What () {

    const { t } = useTranslation();

    const [openIndex, setOpenIndex] = useState(1);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[200px]">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto">
                <div>
                    <p className="pepsi text-[25px] xl:text-[45px] text-[#FF2C57]">{t('home.8')}</p>
                    <p className="text-[#303030] pepsi text-[25px]">{t('home.9')}</p>
                    <p className="inter text-[14px] xl:text-[17px] font-[200] mt-[20px] xl:mt-[40px] max-w-[400px]">{t('home.10')}</p>
                    <Button className="mt-[20px]"/>
                </div>
                <div className="xl:w-[697px] mt-[20px] xl:mt-0">
                    {faqData.map((faq, index) => (
                        <div key={index}>
                            <div className={`flex justify-between mt-[10px] transition-all duration-300 rounded-[20px] ${openIndex === index ? 'h-auto bg-[#2050A0]' : 'bg-[#E4E4E4] h-[54px]'}`}>
                                <div className='mx-[25px]'>
                                    <p className={`mt-[15px] inter text-[14px] font-[700] xl:text-[17px] ${openIndex === index ? 'text-white' : 'text-[#303030]'}`}>{t(faq.question)}</p>
                                    {openIndex === index && (
                                    <p className={`textcolor inter text-[14px] xl:text-[16px] font-[200] mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[802px] ${openIndex === index ? 'text-white' : 'text-[#303030]'}`}>{t(faq.answer)}</p>
                                    )}
                                </div>
                                <div className='cursor-pointer mx-[25px] mt-[5px]' onClick={() => toggleText(index)}>
                                    <img src={button} alt="" className={`${openIndex === index ? 'transition-transform rotate-90' : 'transition-transform'}`}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default What;