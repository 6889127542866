import React from "react";

import { useTranslation, Trans } from "react-i18next";

import main from '../../img/AboutUs/main.png'
import check from '../../img/check.png'

import Fade from 'react-reveal/Fade'
import Button from "../Button";

function Main () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto mt-[50px]">
                <div>
                    <div className="flex flex-col">
                        <p className="text-[#FF2C57] font-[700] text-[35px] xl:text-[70px] pepsi tracking-wider">{t('aboutus.1')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px] max-w-[525px]">{t('aboutus.2')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px] max-w-[525px]">{t('aboutus.3')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px] max-w-[525px]">{t('aboutus.4')}</p>
                        <Button className="mt-[50px]"/>
                    </div>    
                </div>
                <div>
                    <Fade right>
                        <img src={main} alt="" />
                    </Fade>   
                </div>
            </div>
        </div>
    );
}

export default Main;