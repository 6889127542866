import React, { useState } from 'react';

import { useTranslation, Trans } from "react-i18next";

import Button from "../Button";

import switcher2 from '../../img/Services/switcher2.png'

function Swicther2 () {

    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState('forex');

    const renderContent = () => {
        switch(activeTab) {
          case 'forex':
            return <div>
                        <p className="text-[#303030] pepsi text-[25px]">{t('services.11')}</p>
                        <p className='open-sans text-[14px] text-[#2A2B2B] mt-[30px] py-[10px]'>{t('services.13')}</p>
                        <div className='h-px bg-[#AAA]'></div>
                        <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.14')}</p>
                        <div className='h-px bg-[#AAA]'></div>
                        <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.15')}</p>
                        <div className='h-px bg-[#AAA]'></div>
                        <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.16')}</p>
                        <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.17')}</p>
                        <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.18')}</p>
                        <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.19')}</p>
                        <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.20')}</p>
                        <div className='h-px bg-[#AAA]'></div>
                        <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.21')}</p>
                        <div className='h-px bg-[#AAA]'></div>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px]">{t('services.22')}</p>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('services.23')}</p>
                   </div>;
          case 'global-markets':
            return <div className=''>
                        <p className="text-[#303030] pepsi text-[25px]">{t('services.12')}</p>
                        <div className='grid grid-cols-2'>
                            <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.24')}</p>
                            <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.25')}</p>
                        </div>
                        <div className='h-px bg-[#AAA]'></div>
                        <div className='grid grid-cols-2'>
                            <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.26')}</p>
                            <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.25')}</p>
                        </div>
                        <div className='h-px bg-[#AAA]'></div>
                        <div className='grid grid-cols-2'>
                            <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.27')}</p>
                            <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.25')}</p>
                        </div>
                        <div className='h-px bg-[#AAA]'></div>
                        <div className='grid grid-cols-2'>
                            <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.28')}</p>
                            <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.25')}</p>
                        </div>
                        <div className='h-px bg-[#AAA]'></div>
                        <div className='grid grid-cols-2'>
                            <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.29')}</p>
                            <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.25')}</p>
                        </div>
                        <div className='h-px bg-[#AAA]'></div>
                        <div className='grid grid-cols-2'>
                            <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.30')}</p>
                            <p className='open-sans text-[14px] text-[#2A2B2B] py-[10px]'>{t('services.25')}</p>
                        </div>
                        <div className='h-px bg-[#AAA]'></div>
                   </div>;
          default:
            return null;
        }
      };
      
    return(
        <div className="overflow-hidden relative">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px] gap-[100px]">
                <div className="xl:w-[279px] flex flex-col gap-[40px]">
                    <div className='flex flex-col gap-[15px]'>
                        <button className={`font-[500] transition-all inter h-[43px] rounded-[25px] ${activeTab === 'forex' ? 'bg-[#2050A0] text-white' : 'bg-[#E4E4E4] text-[#303030]'}`} onClick={() => setActiveTab('forex')}>{t('services.11')}</button>
                        <button className={`font-[500] transition-all inter h-[43px] rounded-[25px] ${activeTab === 'global-markets' ? 'bg-[#2050A0] text-white' : 'bg-[#E4E4E4] text-[#303030]'}`} onClick={() => setActiveTab('global-markets')}>{t('services.12')}</button>
                    </div>
                    <img src={switcher2} alt="" className='mt-[40px]'/>
                </div>
                <div className='max-w-[900px] mt-[30px] xl:mt-0 flex flex-col mr-auto'>
                    {renderContent()}
                </div>
            </div>
        </div>
    );
}

export default Swicther2;