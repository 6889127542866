import React, { useEffect } from "react";

import Main from '../components/Services/Main'
import Switcher1 from "../components/Services/Swicther1";
import Swicther2 from "../components/Services/Swicther2";
import SocialCopyTrading from "../components/Services/SocialCopyTrading";

function Services () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Switcher1/>
            <Swicther2/>
            <SocialCopyTrading/>
        </div>
    );
}

export default Services;