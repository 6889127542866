import React from "react";

import { useTranslation } from "react-i18next";

import Button from "../Button";

import info1 from '../../img/info(1).png'
import info2 from '../../img/info(2).png'
import info3 from '../../img/info(3).png'

function Information () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="flex flex-col xl:flex-row justify-between ">
                    <div>
                        <p className="pepsi text-[25px] xl:text-[45px] text-[#FF2C57]">{t('home.40')}</p>
                        <p className="text-[#303030] pepsi text-[25px]">{t('home.41')}</p>
                    </div>
                    <div>
                        <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px] max-w-[445px]">{t('home.42')}</p>
                    </div>
                </div>
                <div className="mt-[30px] bg-white rounded-[30px] border border-[#303030] xl:h-[228px] flex flex-col xl:flex-row items-center justify-between p-[12px]">
                    <div className="flex flex-col xl:flex-row items-center">
                        <img src={info1} alt="" className="w-full xl:w-auto"/>
                        <div className="xl:ml-[36px] mt-[20px] xl:mt-0">
                            <p className="inter text-[#303030] text-[16px] xl:text-[25px] font-[800]">{t('home.43')}</p>
                            <p className="max-w-[525px] inter text-[#303030] font-[200] text-[14px] xl:text-[17px] mt-[15px]">{t('home.44')}</p>
                        </div>
                    </div>
                    <a href="https://user.turkmill.net">
                        <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75" fill="none" className="hidden xl:block">
                            <rect width="75" height="75" rx="37.5" fill="#2050A0"/>
                            <path d="M20.8519 51.6904L54.148 23.3102" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M23.3099 20.8516L54.1481 23.3095L51.6901 54.1476" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>
                </div>
                <div className="mt-[20px] bg-white rounded-[30px] border border-[#303030] xl:h-[228px] flex flex-col xl:flex-row items-center justify-between p-[12px]">
                    <div className="flex flex-col xl:flex-row items-center">
                        <img src={info2} alt="" className="w-full xl:w-auto"/>
                        <div className="xl:ml-[36px] mt-[20px] xl:mt-0">
                            <p className="inter text-[#303030] text-[16px] xl:text-[25px] font-[800]">{t('home.45')}</p>
                            <p className="max-w-[525px] inter text-[#303030] font-[200] text-[14px] xl:text-[17px] mt-[15px]">{t('home.46')}</p>
                        </div>
                    </div>
                    <a href="https://user.turkmill.net">
                        <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75" fill="none" className="hidden xl:block">
                            <rect width="75" height="75" rx="37.5" fill="#2050A0"/>
                            <path d="M20.8519 51.6904L54.148 23.3102" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M23.3099 20.8516L54.1481 23.3095L51.6901 54.1476" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>
                </div>
                <div className="mt-[20px] bg-white rounded-[30px] border border-[#303030] xl:h-[228px] flex flex-col xl:flex-row items-center justify-between p-[12px]">
                    <div className="flex flex-col xl:flex-row items-center">
                        <img src={info3} alt="" className="w-full xl:w-auto"/>
                        <div className="xl:ml-[36px] mt-[20px] xl:mt-0">
                            <p className="inter text-[#303030] text-[16px] xl:text-[25px] font-[800]">{t('home.47')}</p>
                            <p className="max-w-[525px] inter text-[#303030] font-[200] text-[14px] xl:text-[17px] mt-[15px]">{t('home.48')}</p>
                        </div>
                    </div>
                    <a href="https://user.turkmill.net">
                        <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75" fill="none" className="hidden xl:block">
                            <rect width="75" height="75" rx="37.5" fill="#2050A0"/>
                            <path d="M20.8519 51.6904L54.148 23.3102" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M23.3099 20.8516L54.1481 23.3095L51.6901 54.1476" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Information;