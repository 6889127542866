import React, { useEffect } from "react";

import Main from '../components/Home/Main'
import What from "../components/Home/What";
import SelfDirected from "../components/Home/SelfDirected";
import Unveiling from "../components/Home/Unveiling";
import Information from "../components/Home/Information";
import Tailored from "../components/Home/Tailored";
import LetsGetStarted from "../components/Home/LetsGetStarted";

function Home () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <What/>
            <SelfDirected/>
            <Unveiling/>
            <Information/>
            <Tailored/>
            <LetsGetStarted/>
        </div>
    );
}

export default Home;