import React from "react";

import { useTranslation } from "react-i18next";

import Button from "../Button";

function Unveiling () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto">
                <div>
                    <p className="pepsi text-[25px] xl:text-[45px] text-[#FF2C57]">{t('home.33')}</p>
                    <p className="text-[#303030] pepsi text-[25px]">{t('home.34')}</p>
                    <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px] max-w-[525px]">{t('home.35')}</p>
                    <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[10px] max-w-[525px]">{t('home.36')}</p>
                    <Button className="mt-[20px] xl:mt-[50px]"/>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <p className="pepsi text-[25px] xl:text-[45px] text-[#FF2C57]">{t('home.37')}</p>
                    <p className="text-[#303030] pepsi text-[25px]">{t('home.38')}</p>
                    <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px] max-w-[525px]">{t('home.39')}</p>
                    <Button className="mt-[20px] xl:mt-[50px]"/>
                </div>
            </div>
        </div>
    );
}

export default Unveiling;