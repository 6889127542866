import React from "react";

import { useTranslation } from "react-i18next";

import card from '../../img/getstarted.png'

import Button from "../Button";

function LetsGetStarted () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto">
                <div>
                    <p className="pepsi text-[25px] xl:text-[45px] text-[#FF2C57]">{t('home.53')}</p>
                    <p className="text-[#FF2C57] pepsi text-[25px]">{t('home.54')}</p>
                    <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px] max-w-[445px]">{t('home.55')}</p>
                    <div className="flex mt-[25px] gap-[30px]">
                        <div className="w-[47px] h-[47px] rounded-full bg-[#2050A0] flex items-center justify-center flex-shrink-0">
                            <p className="text-white open-sans font-[700] text-[16px] xl:text-[23px]">1</p>
                        </div>
                        <div className="flex flex-col justify-between">
                            <p className="text-[14px] xl:text-[17px] open-sans text-[#303030] font-[700]">{t('home.56')}</p>
                            <p className="text-[14px] xl:text-[17px] open-sans text-[#303030] font-[400]">{t('home.57')}</p>
                        </div>
                    </div>
                    <div className="flex mt-[20px] gap-[30px]">
                        <div className="w-[47px] h-[47px] rounded-full bg-[#2050A0] flex items-center justify-center flex-shrink-0">
                            <p className="text-white open-sans font-[700] text-[16px] xl:text-[23px]">2</p>
                        </div>
                        <div className="flex flex-col justify-between">
                            <p className="text-[14px] xl:text-[17px] open-sans text-[#303030] font-[700]">{t('home.58')}</p>
                            <p className="text-[14px] xl:text-[17px] open-sans text-[#303030] font-[400]">{t('home.59')}</p>
                        </div>
                    </div>
                    <div className="flex mt-[20px] gap-[30px]">
                        <div className="w-[47px] h-[47px] rounded-full bg-[#2050A0] flex items-center justify-center flex-shrink-0">
                            <p className="text-white open-sans font-[700] text-[16px] xl:text-[23px]">3</p>
                        </div>
                        <div className="flex flex-col justify-between">
                            <p className="text-[14px] xl:text-[17px] open-sans text-[#303030] font-[700]">{t('home.60')}</p>
                            <p className="text-[14px] xl:text-[17px] open-sans text-[#303030] font-[400]">{t('home.61')}</p>
                        </div>
                    </div>
                    <Button className="mt-[20px] xl:mt-[50px]"/>
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <img src={card} alt="" />
                </div>
            </div>
        </div>
    );
}

export default LetsGetStarted;