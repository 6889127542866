import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

const faqData = [
    {
        question: 'faqs.5',
        answer: 'faqs.6'
    },
    {
        question: 'faqs.7',
        answer: 'faqs.8'
    },
    {
        question: 'faqs.9',
        answer: 'faqs.10'
    },
    {
        question: 'faqs.11',
        answer: 'faqs.12'
    },
    {
        question: 'faqs.13',
        answer: 'faqs.14'
    },
    {
        question: 'faqs.15',
        answer: 'faqs.16'
    },
    {
        question: 'faqs.17',
        answer: 'faqs.18'
    },
    {
        question: 'faqs.19',
        answer: 'faqs.20'
    },
    {
        question: 'faqs.21',
        answer: 'faqs.22'
    },
    {
        question: 'faqs.23',
        answer: 'faqs.24'
    },
    {
        question: 'faqs.25',
        answer: 'faqs.26'
    },
    {
        question: 'faqs.27',
        answer: 'faqs.28'
    },
    {
        question: 'faqs.29',
        answer: 'faqs.30'
    },
    {
        question: 'faqs.31',
        answer: 'faqs.32'
    },
    {
        question: 'faqs.33',
        answer: 'faqs.34'
    },
    {
        question: 'faqs.35',
        answer: 'faqs.36'
    },

]

function Faq () {

    const { t } = useTranslation();

    const [openIndex, setOpenIndex] = useState(0);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return(
        <div className=' pt-[80px] xl:pt-[160px]'>
           <div className="max-w-[1280px] mx-auto">
                <div className="flex justify-between flex-col mx-[20px] xl:mx-0 mt-[15px]">
                    {faqData.map((faq, index) => (
                        <div key={index} className='flex flex-col'>
                            <div className={`flex justify-between xl:h-[54px] transition-all duration-300 ${openIndex === index ? '!h-auto' : ''}`}>
                                <div className='mx-[25px] flex gap-[50px] py-[10px] xl:py-0'>
                                    <div>
                                        <p className='text-[20px] xl:text-[40px] text-[#2050A0] open-sans font-[700]'>{index+1}</p>
                                    </div>
                                    <div>
                                        <p className='xl:mt-[18px] inter text-[14px] xl:text-[17px] text-[#303030] max-w-[200px] xl:max-w-[1280px] font-[700]'>{t(faq.question)}</p>
                                        {openIndex === index && (
                                        <p className='textcolor roboto text-[14px] xl:text-[17px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[802px] inter'>{t(faq.answer)}</p>
                                        )}
                                    </div>     
                                </div>
                                <div className='cursor-pointer mx-[25px] mt-[7px]' onClick={() => toggleText(index)}>
                                    <svg className={openIndex === index ? 'block' : 'hidden'} xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                                        <rect x="6" y="14" width="19" height="3" fill="black"/>
                                    </svg>
                                    <svg className={openIndex === index ? 'hidden' : 'block'} xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                                        <path d="M24.5416 16.7894H16.7916V24.5394H14.2083V16.7894H6.45825V14.2061H14.2083V6.45605H16.7916V14.2061H24.5416V16.7894Z" fill="black"/>
                                    </svg>
                                </div>
                            </div>
                            <div className='h-[2px] bg-black w-full opacity-25'></div>
                        </div>
                    ))}
                </div>
           </div>
        </div>
    );
}

export default Faq;