import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import Button from "../Button";

const data = [
    {
        title: 'accounts.5',
        text1: '1:2000',
        text2: '30%',
        text3: '10',
        text4: '$ 500',
        text5: '$ 10',
        text6: 'Yes',
        text7: '1',
    },
    {
        title: 'accounts.6',
        text1: '1:1000',
        text2: '30%',
        text3: '20',
        text4: '$ 1000',
        text5: '$ 1000',
        text6: 'Yes',
        text7: '10',
    },
    {
        title: 'accounts.7',
        text1: '1:100',
        text2: '10%',
        text3: '30',
        text4: '$ 2500',
        text5: '$ 10000',
        text6: 'Yes',
        text7: '20',
    },
    {
        title: 'accounts.8',
        text1: '1:200',
        text2: '10%',
        text3: '100',
        text4: '$ 1500',
        text5: '$ 5000',
        text6: 'Yes',
        text7: '100',
    },
]

function Main () {

    const { t } = useTranslation();

    const [active, setActive] = useState(0);
      
    return(
        <div className="overflow-hidden relative">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto mt-[50px] xl:mt-[100px]">
                <div>
                    <p className="text-[#FF2C57] font-[700] text-[35px] xl:text-[70px] pepsi tracking-wider">{t('accounts.1')}</p>
                    <p className="inter text-[#303030] font-[700] text-[25px] xl:text-[35px]">{t('accounts.2')}</p>
                    <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[30px] max-w-[525px]">{t('accounts.3')}</p>
                    <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px] max-w-[525px]">{t('accounts.4')}</p>
                    <Button className="mt-[50px]"/>
                </div>
                <div className="flex flex-col xl:flex-row mt-[30px] xl:mt-[100px]">
                    <div className="flex flex-row xl:flex-col gap-[10px]">
                        {data.map((dat, index) => (
                            <div key={index} className={`flex transition-all items-center justify-center w-[107px] h-[55px] rounded-t-[10px] xl:rounded-tl-[10px] xl:rounded-bl-[10px] xl:rounded-tr cursor-pointer ${active === index ? 'bg-[#2050A0]' : 'bg-[#E4E4E4]'}`} onClick={() => setActive(index)}>
                                <p className={`font-[600] open-sans text-[14px] ${active === index ? 'text-white' : 'text-[#303030]'}`}>{t(dat.title)}</p>
                            </div>
                        ))}
                    </div>
                    <div className="xl:w-[386px] h-[326px] boxaccounts !rounded-tr xl:!rounded-tr-[20px] flex flex-col px-[50px] justify-center">
                        <div className="grid grid-cols-2 py-[10px]">
                            <p className="open-sans text-[14px] text-[#303030]">{t('accounts.9')}</p>
                            <p className="open-sans text-[14px] text-[#303030]">{data[active].text1}</p>
                        </div>
                        <div className="h-px bg-[#303030]"></div>
                        <div className="grid grid-cols-2 py-[10px]">
                            <p className="open-sans text-[14px] text-[#303030]">{t('accounts.10')}</p>
                            <p className="open-sans text-[14px] text-[#303030]">{data[active].text2}</p>
                        </div>
                        <div className="h-px bg-[#303030]"></div>
                        <div className="grid grid-cols-2 py-[10px]">
                            <p className="open-sans text-[14px] text-[#303030]">{t('accounts.11')}</p>
                            <p className="open-sans text-[14px] text-[#303030]">{data[active].text3}</p>
                        </div>
                        <div className="h-px bg-[#303030]"></div>
                        <div className="grid grid-cols-2 py-[10px]">
                            <p className="open-sans text-[14px] text-[#303030]">{t('accounts.12')}</p>
                            <p className="open-sans text-[14px] text-[#303030]">{data[active].text4}</p>
                        </div>
                        <div className="h-px bg-[#303030]"></div>
                        <div className="grid grid-cols-2 py-[10px]">
                            <p className="open-sans text-[14px] text-[#303030]">{t('accounts.13')}</p>
                            <p className="open-sans text-[14px] text-[#303030]">{data[active].text5}</p>
                        </div>
                        <div className="h-px bg-[#303030]"></div>
                        <div className="grid grid-cols-2 py-[10px]">
                            <p className="open-sans text-[14px] text-[#303030]">{t('accounts.14')}</p>
                            <p className="open-sans text-[14px] text-[#303030]">{data[active].text6}</p>
                        </div>
                        <div className="h-px bg-[#303030]"></div>
                        <div className="grid grid-cols-2 py-[10px]">
                            <p className="open-sans text-[14px] text-[#303030]">{t('accounts.15')}</p>
                            <p className="open-sans text-[14px] text-[#303030]">{data[active].text7}</p>
                        </div>
                        <div className="h-px bg-[#303030]"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;