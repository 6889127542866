import React, { useEffect } from "react";

import Main from '../components/AboutUs/Main'
import Swicther from "../components/AboutUs/Swicther";

function AboutUs () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Swicther/>
        </div>
    );
}

export default AboutUs;