import React from "react";

import { useTranslation, Trans } from "react-i18next";

import girl from '../../img/Services/girl.png'

function Main () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative flex flex-col xl:flex-row mt-[20px]">
            <div className="xl:w-1/2 bg-[#2050A0] h-[455px]">
                <div className="max-w-[640px] ml-auto mt-[70px]">
                    <p className="spantextservices text-[25px] xl:text-[45px] pepsi text-white tracking-wider mx-[20px] xl:mx-0"><Trans>{t('services.1')}</Trans></p>
                    <p className="text-[25px] text-white pepsi mx-[20px] xl:mx-0">{t('services.2')}</p>
                    <p className="text-[25px] text-white pepsi mt-[10px] mx-[20px] xl:mx-0">{t('services.3')}</p>
                    <a href="https://user.turkmill.net">
                        <div className="buttonmain flex items-center !bg-white mt-[45px] mx-[20px] xl:mx-0">
                            <p className="inter text-[14px] xl:text-[16px] text-black font-[700]">{t('home.7')}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                <rect width="35" height="35" rx="17.5" fill="#2050A0"/>
                                <path d="M7.29175 17.5H27.7084" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.5 7.29199L27.7083 17.5003L17.5 27.7087" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
            <div className="xl:w-1/2 bg-[#FEBED6] h-[455px] relative">
                <img src={girl} alt="" className="absolute bottom-0 xl:left-[200px]"/>
            </div>
        </div>
    );
}

export default Main;