import React from "react";

import { useTranslation, Trans } from "react-i18next";

import Button from "../Button";

import social from '../../img/Services/social.png'

function SocialCopyTrading () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto mt-[80px] xl:mt-[160px]">
                <div className="xl:w-[279px]">
                    <img src={social} alt="" className="w-full xl:w-auto"/>
                </div>
                <div className='max-w-[900px] mt-[30px] xl:mt-0'>
                    <p className="text-[#303030] pepsi text-[25px]">{t('services.31')}</p>
                    <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('services.32')}</p>
                    <p className="text-[#303030] pepsi text-[25px] mt-[30px]">{t('services.33')}</p>
                    <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('services.34')}</p>
                    <p className="text-[#303030] pepsi text-[25px] mt-[30px]">{t('services.35')}</p>
                    <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('services.36')}</p>
                    <p className="text-[#303030] pepsi text-[25px] mt-[100px]">{t('services.37')}</p>
                    <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('services.38')}</p>
                    <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('services.39')}</p>
                    <p className="inter font-[200] text-[#303030] text-[14px] xl:text-[17px] mt-[15px]">{t('services.40')}</p>
                </div>
            </div>
        </div>
    );
}

export default SocialCopyTrading;