import React from "react";

import { useTranslation, Trans } from "react-i18next";

import main from '../../img/FAQs/main.png'
import check from '../../img/check.png'

import Fade from 'react-reveal/Fade'
import Button from "../Button";

function Main () {

    const { t } = useTranslation();
      
    return(
        <div className="overflow-hidden relative">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto">
                <div>
                    <div className="flex flex-col">
                        <p className="text-[#FF2C57] font-[700] text-[115px] xl:text-[180px] pepsi tracking-wider">{t('faqs.1')}</p>
                        <p className="text-[#303030] font-[700] text-[35px] xl:text-[70px] inter leading-[1]">{t('faqs.2')}</p>
                        <p className="pepsi text-[16px] xl:text-[25px] font-[400] text-[#303030] mt-[10px]">{t('faqs.3')}</p>
                        <p className="pepsi text-[16px] xl:text-[25px] font-[400] text-[#303030] mt-[10px]">{t('faqs.4')}</p>
                        <Button className="mt-[50px]"/>
                    </div>    
                </div>
                <div className="mt-[70px]">
                    <Fade right>
                        <img src={main} alt="" />
                    </Fade>   
                </div>
            </div>
        </div>
    );
}

export default Main;