import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import why1 from '../../img/Services/why1.png'
import why2 from '../../img/Services/why2.png'

function WhyShould () {

    const { t } = useTranslation();

    const [active1, setActive1] = useState(false);
    const [active2, setActive2] = useState(false);

    const toggleModal = () => {
        setActive1(!active1);
    };

    const toggleModal2 = () => {
        setActive2(!active2);
    };

    useEffect(() => {
        if (active1) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
      }, [active1]);

      useEffect(() => {
        if (active2) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
      }, [active2]);
      
    return(
        <div className="overflow-hidden relative pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="mt-[20px] bg-white rounded-[30px] border border-[#303030] xl:h-[228px] flex flex-col xl:flex-row items-center justify-between p-[12px]">
                    <div className="flex flex-col xl:flex-row items-center">
                        <img src={why1} alt="" className="w-full xl:w-auto"/>
                        <div className="xl:ml-[36px] mt-[20px] xl:mt-0">
                            <p className="inter text-[#303030] text-[16px] xl:text-[25px] font-[800]">{t('accounts.16')}</p>
                            <p className="max-w-[627px] inter text-[#303030] font-[200] text-[14px] xl:text-[17px] mt-[15px]">{t('accounts.17')}</p>
                        </div>
                    </div>
                    <div className="buttonaccounts flex items-center justify-center cursor-pointer mt-[20px]" onClick={toggleModal}>
                        <p className="open-sans text-[16px] text-[#313131]">{t('accounts.20')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                            <rect width="35" height="35" rx="17.5" fill="#2050A0"/>
                            <path d="M7.29163 17.5H27.7083" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.5 7.29199L27.7083 17.5003L17.5 27.7087" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div className="mt-[20px] bg-white rounded-[30px] border border-[#303030] xl:h-[228px] flex flex-col xl:flex-row items-center justify-between p-[12px]">
                    <div className="flex flex-col xl:flex-row items-center">
                        <img src={why2} alt="" className="w-full xl:w-auto"/>
                        <div className="xl:ml-[36px] mt-[20px] xl:mt-0">
                            <p className="inter text-[#303030] text-[16px] xl:text-[25px] font-[800]">{t('accounts.18')}</p>
                            <p className="max-w-[627px] inter text-[#303030] font-[200] text-[14px] xl:text-[17px] mt-[15px]">{t('accounts.19')}</p>
                        </div>
                    </div>
                    <div className="buttonaccounts flex items-center justify-center cursor-pointer mt-[20px]" onClick={toggleModal2}>
                        <p className="open-sans text-[16px] text-[#313131]">{t('accounts.20')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                            <rect width="35" height="35" rx="17.5" fill="#2050A0"/>
                            <path d="M7.29163 17.5H27.7083" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M17.5 7.29199L27.7083 17.5003L17.5 27.7087" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
            {active1 && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-[999] px-[20px]">
                    <div className="h-[600px] w-full xl:w-[1280px] rounded-[30px] border border-[#303030] bg-white relative p-[12px] flex flex-col xl:flex-row">
                        <div className="buttonaccounts flex items-center justify-center cursor-pointer absolute right-[30px] bottom-[40px]" onClick={toggleModal}>
                            <p className="open-sans text-[16px] text-[#313131]">{t('accounts.21')}</p> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                <rect width="35" height="35" rx="17.5" fill="#2050A0"/>
                                <path d="M7.29163 17.5H27.7083" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.5 7.29199L27.7083 17.5003L17.5 27.7087" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <img src={why1} alt="" className="w-full xl:w-auto xl:h-[204px]"/>
                        <div className="xl:ml-[36px] mt-[20px] xl:mt-0 h-[180px] xl:h-auto overflow-y-scroll xl:overflow-y-hidden">
                            <p className="inter text-[#303030] text-[16px] xl:text-[25px] font-[800]">{t('accounts.16')}</p>
                            <p className="inter text-[#303030] font-[200] text-[14px] xl:text-[17px] mt-[15px]">{t('accounts.22')}</p>
                            <p className="inter text-[#303030] font-[200] text-[14px] xl:text-[17px] mt-[15px]">{t('accounts.23')}</p>
                            <p className="inter text-[#303030] font-[200] text-[14px] xl:text-[17px] mt-[15px]">{t('accounts.24')}</p>
                            <p className="inter text-[#303030] font-[200] text-[14px] xl:text-[17px] mt-[15px]">{t('accounts.25')}</p>
                        </div>
                    </div>
                </div>
            )}
            {active2 && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-[999] px-[20px]">
                    <div className="h-[600px] w-full xl:w-[1280px] rounded-[30px] border border-[#303030] bg-white relative p-[12px] flex flex-col xl:flex-row">
                        <div className="buttonaccounts flex items-center justify-center cursor-pointer absolute right-[30px] bottom-[40px]" onClick={toggleModal2}>
                            <p className="open-sans text-[16px] text-[#313131]">{t('accounts.21')}</p> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                <rect width="35" height="35" rx="17.5" fill="#2050A0"/>
                                <path d="M7.29163 17.5H27.7083" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.5 7.29199L27.7083 17.5003L17.5 27.7087" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <img src={why2} alt="" className="w-full xl:w-auto xl:h-[204px]"/>
                        <div className="xl:ml-[36px] mt-[20px] xl:mt-0 h-[180px] xl:h-auto overflow-y-scroll xl:overflow-y-hidden">
                            <p className="inter text-[#303030] text-[16px] xl:text-[25px] font-[800]">{t('accounts.18')}</p>
                            <p className="inter text-[#303030] font-[200] text-[14px] xl:text-[17px] mt-[15px]">{t('accounts.26')}</p>
                            <p className="inter text-[#303030] font-[200] text-[14px] xl:text-[17px] mt-[15px]">{t('accounts.27')}</p>
                            <p className="inter text-[#303030] font-[200] text-[14px] xl:text-[17px] mt-[15px]">{t('accounts.28')}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default WhyShould;