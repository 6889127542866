import React, { useEffect } from "react";

import Main from '../components/FAQs/Main'
import Faq from "../components/FAQs/Faq";

function FAQs () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Faq/>
        </div>
    );
}

export default FAQs;